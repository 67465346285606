<template lang="pug">
div
  v-container(fluid)
    DataFilter(:filterProps="filters", :items="dataset", :paginate="false")
      template(v-slot:actions)
        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2.mt-1(
              v-on="on",
              color="primary",
              fab,
              outlined,
              small,
              @click="getData"
            )
              v-icon mdi mdi-refresh
          span Atualizar

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mt-1(
              v-if="isGerente",
              v-on="on",
              color="primary",
              fab,
              outlined,
              small,
              @click="createCadastroDialog"
            )
              v-icon mdi mdi-plus
          span Incluir

        v-divider(vertical, v-if="isGerente")

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.mr-5.mt-1(
              v-if="isGerente",
              v-on="on",
              color="primary",
              fab,
              outlined,
              small,
              @click="dialogEnviar = true"
            )
              v-icon mdi mdi-cube-send
          span Forçar Envio

      template(v-slot:default="{ items }")
        v-data-table.bancosTable(
          :headers="headers",
          :items="items",
          item-key="id",
          :dense="isGerente",
          :footer-props="footerProps"
        )
          template(v-slot:item.actions="{ item }")
            td.text-center.pr-2.pl-0
              v-tooltip(bottom, v-if="isGerente")
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on",
                    color="primary",
                    fab,
                    small,
                    icon,
                    @click="editCadastroDialog(item)"
                  )
                    v-icon mdi mdi-pencil-circle-outline
                span Editar

          template(v-slot:item.criadoem="{ item }")
            td.text-center
              span {{ new Date(item.criadoem).toLocaleString() }}

          template(v-slot:item.atualizadoem="{ item }")
            td.text-center
              span {{ new Date(item.atualizadoem).toLocaleString() }}

  v-dialog(v-model="dialogEnviar", persistent, max-width="500")
    v-card(style="width: 500px")
      v-card-title
        h3 Forçar envio de dados
      v-card-text.mt-4
        div(style="font-size: 14px") Selecione um cliente para encaminhar o comando de atualização dos dados de instituição bancaria.

        v-autocomplete.mt-4(
          v-model="clienteSelected",
          :items="datasetClientes",
          :disabled="datasetClientes.length === 0",
          :loading="datasetClientes.length === 0",
          placeholder="Digite ou selecione um cliente",
          return-object,
          item-text="nome",
          item-value="idcliente",
          label="Cliente",
          dense,
          outlined,
          hide-details
        )

      v-card-actions.pa-2.pt-0.pr-1.pb-4
        v-spacer
        v-btn(color="primary", outlined, @click="dialogEnviar = false")
          span Cancelar
        v-btn(
          color="primary",
          :disabled="dialogEnviarLoadingSend",
          outlined,
          @click="sendCliente()"
        )
          span Enviar

  v-dialog(
    v-model="dialogCadastro",
    v-if="dialogCadastro",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Instituições Financeiras - {{ isDisabled ? "Alteração" : "Inclusão" }}
      v-card-text.mt-6
        v-form(ref="form", v-model="formValid")
          v-row.mt-0.pt-0(no-gutters)
            v-col.pr-2(cols="12", sm="6", md="3")
              label Código
              v-text-field(
                v-model="registroCadastro.codigo",
                :disabled="isDisabled",
                placeholder="Cód.",
                type="number",
                outlined,
                dense,
                :rules="[rules.required]"
              )
            v-col.pl-2(cols="12", sm="6", md="9")
              label Instituição
              v-text-field(
                v-model="registroCadastro.nome",
                placeholder="Banco",
                outlined,
                dense,
                :rules="[rules.required, rules.min5]"
              )
          v-row.mt-0.pt-0(no-gutters)
            v-col.pr-2(cols="12", sm="6", md="5")
              label Nome Abreviado
              v-text-field(
                v-model="registroCadastro.abreviadobanco",
                placeholder="Abreviado",
                outlined,
                dense
              )
            v-col.pl-2(cols="12", sm="6", md="7")
              label Logotipo
              v-text-field(
                v-model="registroCadastro.logotipobanco",
                placeholder="Caminho\\pasta\\imagem.ext",
                outlined,
                dense
              )
      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="closeCadastroDialog")
          span Cancelar
        v-btn(color="success", outlined, @click="saveCadastro")
          span Salvar
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { clientes as api } from "@/api";

export default {
  data() {
    return {
      dialogEnviar: false,
      dialogEnviarLoadingSend: false,
      isGerente: false,
      formValid: false,
      dialogCadastro: false,
      registroCadastro: {
        codigo: null,
        nome: "",
        abreviadobanco: "",
        logotipobanco: "",
      },
      filters: {
        includeSearch: true,
        items: [],
        searchText: "",
        useSearchHash: false,
      },
      dataset: [],
      datasetClientes: [],
      clienteSelected: null,
      modelBanco: {
        codigo: null,
        nome: "",
        abreviadobanco: "",
        logotipobanco: "",
      },
      headers: [
        { text: "Cod.", value: "codigo", align: "center", width: "90px" },
        { text: "Instituição", value: "nome" },
        { text: "Abreviado", value: "abreviadobanco" },
        { text: "Logotipo", value: "logotipobanco" },
        {
          text: "Data de Criação",
          value: "criadoem",
          align: "center",
          width: "200px",
        },
        {
          text: "Data de Atualização",
          value: "atualizadoem",
          align: "center",
          width: "210px",
        },
      ],
      footerProps: {
        itemsPerPageOptions: [50, 100, 300, 500, -1],
        itemsPerPageAllText: "Todos",
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: "Itens por página",
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório",
        min5: (value) => (value && value.length >= 5) || "Mínimo 5 caracteres",
      },
    };
  },

  computed: {
    isDisabled() {
      return !!this.registroCadastro.id;
    },
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    async getData() {
      this.showLoader("Carregando dados...");
      try {
        const response = await csapi.get("/v1/databridge/bancos");

        // response.data.forEach((item) => {
        //   item.criadoem = new Date(item.criadoem).toLocaleString();
        //   item.atualizadoem = new Date(item.atualizadoem).toLocaleString();
        // });

        this.dataset = response.data;
      } catch (error) {
        this.$toast.error("Erro ao carregar dados");
      } finally {
        this.hideLoader();
      }
    },

    async getDataClientes() {
      api.refreshAuthProd(this.$store.state.auth);

      this.showLoader("Carregando clientes...");

      try {
        this.datasetClientes = await api.getAll();
        this.datasetClientes.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
      } catch (e) {
        this.$toast.error("Ocorreu algum problema ao buscar as informações");
      } finally {
        this.hideLoader();
      }
    },

    closeCadastroDialog() {
      this.registroCadastro = JSON.parse(JSON.stringify(this.modelBanco));
      this.dialogCadastro = false;
    },

    createCadastroDialog() {
      this.registroCadastro = JSON.parse(JSON.stringify(this.modelBanco));
      this.dialogCadastro = true;
    },

    editCadastroDialog(item) {
      this.registroCadastro = JSON.parse(JSON.stringify(item));
      this.dialogCadastro = true;
    },

    async saveCadastro() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let success = false;

      this.registroCadastro.codigo = parseInt(this.registroCadastro.codigo);
      console.log(this.registroCadastro);

      try {
        if (this.registroCadastro.id) {
          const link = `/v1/databridge/bancos/${this.registroCadastro.codigo}`;
          await csapi.put(link, {
            nome: this.registroCadastro.nome,
            abreviadobanco: this.registroCadastro.abreviadobanco ?? "",
            logotipobanco: this.registroCadastro.logotipobanco ?? "",
          });
          this.$toast.success("Registro atualizado com sucesso");
          success = true;
        } else {
          const link = `/v1/databridge/bancos`;
          await csapi.post(link, this.registroCadastro);
          this.$toast.success("Registro salvo com sucesso");
          success = true;
        }
      } catch (error) {
        let msg = error.response.data.message;
        if (error.response.data.errors) {
          msg = error.response.data.errors[0].message;
        }
        this.$toast.error("Erro ao salvar registro. msg server: " + msg);
      }

      if (success) {
        this.closeCadastroDialog();
        this.getData();
      }
    },

    compareVersions(v1, v2) {
      const v1Parts = v1.split(".").map(Number);
      const v2Parts = v2.split(".").map(Number);

      for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
        const v1Part = v1Parts[i] || 0;
        const v2Part = v2Parts[i] || 0;

        if (v1Part > v2Part) return 1;
        if (v1Part < v2Part) return -1;
      }

      return 0;
    },

    async sendCliente() {
      this.dialogEnviarLoadingSend = true;
      if (!this.clienteSelected) {
        this.$toast.error("Selecione um cliente");
        return;
      }

      if (
        this.compareVersions(this.clienteSelected.versao, "2024.10.1.0") <= 0
      ) {
        this.$toast.error("Server do cliente está em uma versão antiga");
        this.dialogEnviarLoadingSend = false;
        return;
      }

      try {
        const link = `/v1/databridge/integrate`;
        const data = {
          hash: [this.clienteSelected.hash],
          processo: "bancos",
        };

        const response = await csapi.post(link, data);

        if (response.status.toString().startsWith("2")) {
          this.$toast.success("Encaminhado com sucesso");
          this.clienteSelected = null;
          this.dialogEnviar = false;
        }
      } catch (error) {
        let msg = error.response.data.message;
        if (error.response.data.errors) {
          msg = error.response.data.errors[0].message;
        }
        this.$toast.error("Erro ao encaminhar registro. msg server: " + msg);
      }

      this.dialogEnviarLoadingSend = false;
    },

    createActionColumn() {
      this.headers.push({
        text: "Ações",
        value: "actions",
        sortable: false,
        width: "50px",
        align: "center",
      });
    },

    adminRole() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const adminRole = [];

      roles.forEach((role) => {
        if (role.name.toUpperCase() === "GERENTE") {
          adminRole.push(role);
        }
      });

      this.isGerente = adminRole.length > 0;
    },
  },
  mounted() {
    // Cabeçalho
    this.isControl(true);
    this.setTitle("Instituições Financeiras");

    // Regras de acesso
    this.adminRole();
    if (this.isGerente) this.createActionColumn();

    // Carrega os dados
    this.getData();
    this.getDataClientes();
  },
};
</script>

<style>
.bancosTable .v-data-table__wrapper {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
</style>